import "./App.css";
import "./css/bootstrap.css";
import "./css/bootstrap.min.css";
import "./css/style.css";
import "./css/flexslider.css";
import "./css/ionicons.min.css";
import "./css/jquery.fullPage.css";
import "./css/material-design-iconic-font.min.css";
import "./css/modalcomponent.css";
import "./css/zoomslider.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

function App() {
  const { t, i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const lan = i18n.language === "en" ? true : false;

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setIsDropdownOpen(false); // Dropdown'u kapat
  };

  //State
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  //Scripts functions
  const scriptSources = [
    "/js/jquery-2.1.4.min.js",
    "/js/bootstrap.min.js",
    "/js/jquery.backstretch.min.js",
    "/js/jquery.waypoints.js",
    "/js/jquery.counterup.min.js",
    "/js/jquery.ajaxchimp.js",
    "/js/easypiechart.js",
    "/js/jquery.easings.min.js",
    "/js/jquery.fullPage.js",
    "/js/TimeCircles.js",
    "/js/parsley.min.js",
    "/js/custom.js",
  ];

  const scripts = scriptSources.map((src) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
    return script;
  });

  useEffect(() => {
    return () => {
      scripts.forEach((script) => {
        document.body.removeChild(script);
      });
    };
  }, []);

  //Counter function
  useEffect(() => {
    // Countdown date
    const countdownDate = new Date("2024-11-06T00:00:00").getTime();

    const countdownFunction = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      // Time calculations
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update state with the new time values
      setTimeLeft({ days, hours, minutes, seconds });

      // If the countdown is finished, stop the interval
      if (distance < 0) {
        clearInterval(countdownFunction);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(countdownFunction); // Cleanup on unmount
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }
  , []);

  return (

    <>
    {loading && (
        <div style={{position:"fixed",display:"flex", justifyContent:"center", alignItems:"center", top: 0, left: 0, right:0, bottom:0, backgroundColor:"rgba(0,0,0,0.95)", zIndex: 9999}}>
          <div style={{fontSize:"30px", color:"white"}}>Loading...</div>
        </div>
      )}
      <div className="image-background">
{/*      <div id="preloader">
        <div id="status">
          <div className="spinner">Loading...</div>
        </div>
      </div>  */}
        <header className="header">
          <nav className="navbar navbar-custom">
            <div className="container-fluid">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  onClick={toggleMenu}
                  aria-expanded={isMenuOpen ? "true" : "false"}
                  data-target="#bs-example-navbar-collapse-1"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a
                  className="navbar-brand logo"
                  href="index_2.html"
                  style={{ padding: 0 }}
                >
                  {" "}
                  <img src="/logo.png" alt="logo" width={200} />
                </a>
              </div>

              <div
                className="collapse navbar-collapse"
                id="bs-example-navbar-collapse-1"
                aria-expanded={isMenuOpen ? "true" : "false"}
                style={{ display: isMenuOpen ? "block" : "none" }}
              >
                <ul className="nav navbar-nav navbar-right" id="menu">
                  <li className="active" data-menuanchor="1stPage">
                    <a href="#1stPage" className="active">
                      {t("header.home")}
                    </a>
                  </li>
                  <li data-menuanchor="2ndPage">
                    <a href="#2ndPage">{t("header.about")}</a>
                  </li>
                  <li data-menuanchor="3rdPage">
                    <a href="#3rdPage">{t("header.partners")}</a>
                  </li>
                  <li data-menuanchor="4thPage">
                    <a href="#4thPage">{t("header.content")}</a>
                  </li>
                  <li data-menuanchor="5thPage">
                    <a href="#5thPage">{t("header.contact")}</a>
                  </li>
                  <li data-menuanchor="6thPage">
                    <a
                      href="https://forms.gle/ED3adFiYzWN8v3FY6"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("header.apply")}
                    </a>
                  </li>
                  <li style={{ position: "relative" }}>
                    <a onClick={toggleDropdown} style={{ cursor: "pointer" }}>
                      {t("header.language")}
                    </a>
                    {isDropdownOpen && (
                      <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "#000000b3",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        borderRadius: "4px",
                        zIndex: 1,
                        fontSize: "16px",
                        width: "max-content", 
                      }}
                      >
                        <button
                          className="language"
                          onClick={() => changeLanguage("en")}
                          style={{
                            display: "block",
                            width: "100%",
                            padding: "8px 16px",
                            background: "none",
                            border: "none",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                        >
                          EN
                        </button>
                        <button
                          className="language"
                          onClick={() => changeLanguage("tr")}
                          style={{
                            display: "block",
                            width: "100%",
                            padding: "8px 16px",
                            background: "none",
                            border: "none",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                        >
                          TR
                        </button>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>

        <div id="fullpage">
          <div className="section home image-background" id="section0">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <div className="back-color section-title">
                    <h1 className="text-center"  >
                      {!lan && (
                        <span className="h1-mobil" style={{ color: "#d9ac4c" }}>
                          {t("home.land")}
                        </span>
                      )}
                      <span className="h1-mobil" style={{ fontSize: "40px" }}>{t("home.sec1")}</span>
                      <br></br>
                      <br></br>
                      <span id="days" className="h1-mobil" style={{ fontSize: "40px" }}>
                        {timeLeft.days}
                      </span>
                      {t("home.days")},
                      <span className="h1-mobil" id="hours" style={{ fontSize: "40px" }}>
                        {timeLeft.hours}
                      </span>
                      {t("home.hours")},
                      <span className="h1-mobil" id="minutes" style={{ fontSize: "40px" }}>
                        {timeLeft.minutes}
                      </span>
                      {t("home.minutes")},
                      <span className="h1-mobil" id="seconds" style={{ fontSize: "40px" }}>
                        {timeLeft.seconds}
                      </span>
                      {t("home.seconds")}
                      {lan && (
                        <span style={{ color: "#d9ac4c" }}>
                          {t("home.landof")}
                        </span>
                      )}
                      <br></br>
                      <br></br>
                      {t("home.sec2")}
                      <br></br>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section" id="section1">
            <div className="container">
              <div className="common-container">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1">
                    <div className="subscribe-section section-title back-color">
                      <h1 className="text-center">{t("about.title")}</h1>
                      <p className="text-center " style={{ fontSize: "20px" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {t("about.bold")}
                        </span>
                        {t("about.content")}
                        <span style={{ fontWeight: "bold" }}>
                          {t("about.bold2")}
                        </span>
                        {t("about.content2")}
                        <span style={{ fontWeight: "bold" }}>
                          {t("about.bold3")}
                        </span>
                        {t("about.content3")}
                        <span style={{ fontWeight: "bold" }}>
                          {t("about.bold4")}
                          </span>
                        {t("about.content4")}
                        {<br></br>}
                        {<br></br>}
                        {t("about.content5")}
                        <span style={{ fontWeight: "bold" }}>
                          {t("about.bold5")}
                        </span>
                        {t("about.content6")}
                        <span style={{ fontWeight: "bold" }}>
                          {t("about.bold6")}
                        </span>
                        {t("about.content7")}
                        <span style={{ fontWeight: "bold" }}>
                          {t("about.bold7")}
                        </span>
                        {t("about.content8")}
                        <span style={{ fontWeight: "bold" }}>
                          {t("about.bold8")}
                        </span>
                        {t("about.content9")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section" id="section2">
            <div className="container">
              <div className="common-container">
                <div className="row partner-h1">
                  <div className="col-md-10 col-md-offset-1 back-color ">
                    <div className="services-section section-title">
                      <h1 className="text-center">{t("partners.title")}</h1>
                    </div>
                  </div>
                </div>
                <div className="row" style={{display:"flex", justifyContent: "center"}}>
                  <div className="col-md-10 col-sm-12 back-color partner-row" style={{display:"flex", justifyContent: "center", flexDirection:'row'}}>
                    <div style={{display:'none', fontSize: '16px'}} className="partner-title"><h1 className="text-center">{t("partners.title")}</h1></div>
                    <div className="col-md-6 col-sm-12">
                      <div className="service-details">
                        <div className="services-icon">
                          <img
                            src="./bug-disi.png"
                            alt="icon"
                            style={{ width: "115px" }}
                          />
                        </div>
                        <h1
                          className="services-title"
                          style={{ fontSize: "18px" }}
                        >
                          {t("partners.founding")}
                        </h1>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12"> 
                      <div className="service-details">
                        <div className="services-icon">
                          <img
                            src="./cultic.png"
                            alt="icon"
                            style={{ width: "115px" }}
                          />
                        </div>
                        <h1
                          className="services-title"
                          style={{ fontSize: "18px" }}
                        >
                          {t("partners.temaanddesign")}
                        </h1>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12"> 
                      <div className="service-details">
                        <div className="services-icon">
                          <img
                            src="./indieway.png"
                            alt="icon"
                            style={{ width: "105px" }}
                          />
                        </div>
                        <h1
                          className="services-title"
                          style={{ fontSize: "18px" }}
                        >
                          {t("partners.organizational")}
                        </h1>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <div className="service-details">
                        <div className="services-icon">
                          <img
                            src="./games-from.png"
                            alt="icon"
                            style={{ width: "100px" }}
                          />
                        </div>
                        <h1
                          className="services-title"
                          style={{ fontSize: "18px" }}
                        >
                          {t("partners.organizational")}
                        </h1>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <div className="service-details">
                        <div className="services-icon">
                          <img
                            src="./GameDev.png"
                            alt="icon"
                            style={{ width: "120px"}}
                          />
                        </div>
                        <h1
                          className="services-title"
                          style={{ fontSize: "18px" }}
                        >
                          {t("partners.organizational")}
                        </h1>
                      </div>
                    </div>
                  <div className="col-md-6 col-sm-12 ">
                      <div className="service-details">
                        <div className="services-icon">
                          <img
                            src="./GameAscension.png"
                            alt="icon"
                            style={{ width: "110px" }}
                          />
                        </div>
                        <h1
                          className="services-title"
                          style={{ fontSize: "18px" }}
                        >
                          {t("partners.organizational")}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section" id="section3">
            <div className="container">
              <div className="common-container">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1 back-color">
                    <div className="about-section section-title">
                      <h1 className="text-center">{t("content.title")}</h1>
                      <p className="text-center" style={{ fontSize: "20px" }}>
                        {t("content.content")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section" id="section5">
            <div className="container">
              <div className="common-container contact-container">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1 back-color">
                    <div className="contact-section section-title">
                      <h1 className="text-center">{t("contact.title")}</h1>
                      <p className="text-center">
                        <a
                          href="mailto:hello@lotc.games"
                          style={{ color: "#d9ac4c", fontSize: "20px" }}
                        >
                          hello@lotc.games
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section" id="section6">
            <div className="container">
              <div className="common-container contact-container">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1 back-color">
                    <div className="contact-section section-title">
                      <h1 className="text-center" style={{display: 'flex', justifyContent: 'center'}}>
                        <a
                          className="col-md-6 col-sm-12 "
                          style={{ color: "#d9ac4c" }}
                          href="https://forms.gle/ED3adFiYzWN8v3FY6"
                          target="_blank"
                          rel="noreferrer"
                          >
                          <span style={{ fontWeight: "bold" }}>
                          {t("Apply.content")}
                          </span>
                          </a>
                      </h1>                  
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer
          className="footer onstart animated"
          data-animation="fadeInUp"
          data-animation-delay="800"
        >
          <p className="copyright">
            © 2024 BUG Lab TEKMER - All Rights Reserved
          </p>
        </footer>

        <div
          className="backstretch"
          style={{
            left: "0px",
            top: "0px",
            overflow: "hidden",
            margin: "0px",
            padding: "0px",
            height: "100vh",
            width: "100vw",
            zIndex: "-999999",
            position: "fixed",
          }}
        >
          <img
            src="/home-bg.png"
            style={{
              position: "absolute",
              margin: "0px",
              padding: "0px",
              border: "none",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              maxHeight: "none",
              maxWidth: "none",
              zIndex: "-999999",
              left: "0px",
              top: "0px",
            }}
            alt=""
          />
        </div>

       <div id="fp-nav" className="right" style={{ marginTop: "-107.5px" }}>
        <ul>
          <li>
            <a href="#1stPage" class="active">
              <span></span>
            </a>
            <div class="fp-tooltip right">{t("header.home")}</div>
          </li>
          <li>
            <a href="#2ndPage" class="">
              <span></span>
            </a>
            <div class="fp-tooltip right">{t("header.about")}</div>
          </li>
          <li>
            <a href="#3rdPage" class="">
              <span></span>
            </a>
            <div class="fp-tooltip right">{t("header.partners")}</div>
          </li>
          <li>
            <a href="#4thPage" class="">
              <span></span>
            </a>
            <div class="fp-tooltip right">{t("header.content")}</div>
          </li>
          <li>
            <a href="#5thPage" class="">
              <span></span>
            </a>
            <div class="fp-tooltip right">{t("header.contact")}</div>
          </li>
          <li>
            <a href="#6thPage" class="">
              <span></span>
            </a>
            <div class="fp-tooltip right">{t("header.apply")}</div>
          </li>
        </ul>
      </div>  
      </div>
    </>

  );
}

export default App;
